import '@ascii.fyi/ascii-fyi';

document.addEventListener('keydown', function(e) {
  if (navigator.platform === 'MacIntel' || navigator.platform === 'iPhone') {
    // Cmd-A selects the ASCII table
    if (!(e.which === 65 /* A */ && !e.ctrlKey && !e.altKey && !e.shiftKey && e.metaKey)) return;
  } else {
    // Ctrl-A selects the ASCII table
    if (!(e.which === 65 /* A */ && e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey)) return;
  }

  let $ascii = document.querySelector('ascii-fyi');
  $ascii.selectAscii();
  e.preventDefault();
});
